import React, { useState } from "react";
import { connect } from 'react-redux';

import {
    authLogin,
} from '../../store/Actions';

const Login = ({
    doAuthLogin,
}) => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const handleLogin = () => {
        doAuthLogin({email, password});
    };
    return (
        <div className="bg-neutral-400 h-screen pt-20">

            <div className="flex flex-col max-w-md m-auto mt-20 border border-md shadow-lg">
                <div className="bg-gray-200/50 p-5">
                    <h1>Login</h1>
                        Email:
                        <br />
                    <input
                        type="text"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className="w-full p-3"
                        />
                    <br />
                        Password:
                    <br />
                    <input
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        className="w-full p-3"
                        />
                    <br />
                    <br />

                    <button className="card-button bg-green-200 p-3 rounded-md" onClick={handleLogin}>Log In</button><br/>
                </div>
            </div>
        </div>
    );
};

const mapDispatchToProps = (dispatch) => ({
    doAuthLogin: (user) => dispatch(authLogin(user)),
});

const mapStateToProps = (state) => ({
    checkout: state.checkout,
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
