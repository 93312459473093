import React, { useState, useEffect, useCallback, useMemo } from "react";
import { connect } from 'react-redux';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { nanoid } from 'nanoid'
import {
    firebaseCreateCollection,
    firebaseUpdateCollection,
    firebaseDeleteCollection,
    firebaseGetCollection,
} from '../../store/Actions';

import AdminLayout from "../../components/admin/Layout";
import CustomInput from "../../components/admin/CustomInput";

const PostAdmin = ({
    state,
    user,
    token,
    doFirebaseCreateCollection,
    doFirebaseUpdateCollection,
    doFirebaseDeleteCollection,
    doFirebaseGetCollection,
    post,
}) => {
    const { slug } = useParams();
    const defaultPost = useMemo(() => {
        return {
            id: "",
            uuid: nanoid(6),
            mainImage: "",
            altText: "",
            title: "",
            body: "",
            type: "life",
            token: token,
            metaTitle: "",
            metaDescription: "",
            table: "posts",
        }
    }, [token]);
    const [formData, setFormData] = useState(defaultPost);
    const navigate = useNavigate();
    const memoizedDoFirebaseGetCollection = useCallback(() => {
        doFirebaseGetCollection({
            "table":"posts",
            "slug": slug
        });
    }, [doFirebaseGetCollection, slug]);

    useEffect(() => {
        if (slug === "new") return;
        memoizedDoFirebaseGetCollection();
    }, [memoizedDoFirebaseGetCollection, slug]);

    useEffect(() => {
        if (slug === "new") return;
        setFormData((prevFormData) => ({
            ...defaultPost,
            ...post,
        }));
    }, [post, slug, defaultPost]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        for (const key in formData) {
            if (formData[key] === "" && key !== "id") return false;
        }
        if (slug === "new") {
            doFirebaseCreateCollection(formData);
            navigate(`/admin/post/${formData["uuid"]}`);
        } else {
            doFirebaseUpdateCollection(formData);
        }
    }

    const onClickDelete = async (event) => {
        await doFirebaseDeleteCollection(formData);
        navigate("/admin/posts");
    }

    return (
        <AdminLayout>
            <div className="text-3xl p-4 pl-10 bg-stone-300/50">
                Home > Posts > { slug } >
                <Link to="/admin/post/new" className="bg-green-200 hover:bg-green-300 rounded-lg p-2 m-2">Add New Post</Link>
            </div>
            <form onSubmit={handleSubmit} id="postForm" className="flex flex-row">
                <div className="grid grid-cols-2 gap-10 max-w-6xl p-10">
                    <div>
                        ID:<br/>
                        <input className="text-2xl rounded-lg shadow-lg p-3" value={formData.id} disabled={true}/>
                    </div>
                    <div>
                        UUID:<br/>
                        <input className="text-2xl rounded-lg shadow-lg p-3" value={formData.uuid} disabled={true}/>
                    </div>
                    <div className="col-span-2">
                        Title:<br/>
                        <CustomInput
                            onChange={ handleInputChange }
                            value={ formData.title }
                            name={"title"}
                            type={"text"}
                            placeholder={"Idiom Title"}
                            disabled={false}
                        />
                    </div>
                    <div>
                        Main Image:<br/>
                        <CustomInput
                            onChange={ handleInputChange }
                            value={ formData.mainImage }
                            name={"mainImage"}
                            type={"text"}
                            placeholder={"Idiom Image Alt Text"}
                            disabled={false}
                        />
                    </div>
                    <div>
                        Alt Text:<br/>
                        <CustomInput
                            onChange={ handleInputChange }
                            value={ formData.altText }
                            name={"altText"}
                            type={"text"}
                            placeholder={"Idiom Image Alt Text"}
                            disabled={false}
                        />
                    </div>
                    <div className="col-span-2">
                        Body:<br/>
                        <CustomInput
                            onChange={ handleInputChange }
                            value={ formData.body }
                            name={"body"}
                            type={"textarea"}
                            placeholder={"Idiom Description"}
                            disabled={false}
                        />
                    </div>
                    <div>
                        Type:<br/>
                        <select className="text-3xl p-3 rounded-lg shadow-lg w-full" name="type" id="type"
                            onChange={ handleInputChange }
                            value={formData.type}>
                            <option value="tutorials">Tutorials</option>
                            <option value="life">Life</option>
                            <option value="jobHunt">Job Hunt</option>
                        </select>
                    </div>
                    <div>
                        Meta Title:<br/>
                        <CustomInput
                            onChange={ handleInputChange }
                            value={ formData.metaTitle }
                            name={"metaTitle"}
                            type={"text"}
                            placeholder={"Meta Title"}
                            disabled={false}
                            maxlength="70"
                        />
                    </div>
                    <div className="col-span-2">
                        Meta Description:<br/>
                        <CustomInput
                            onChange={ handleInputChange }
                            value={ formData.metaDescription }
                            name={"metaDescription"}
                            type={"text"}
                            placeholder={"Meta Description"}
                            disabled={false}
                            maxlength="155"
                        />
                    </div>
                    <button className="text-3xl p-3 rounded-lg shadow-lg bg-green-200 hover:bg-green-300 col-start-1" form="postForm">
                        {(slug === "new")? "Create": "Update"}
                    </button>
                    {(slug !== "new")&&
                         <button onClick={onClickDelete} className="text-3xl p-3 rounded-lg shadow-lg bg-red-200 hover:bg-red-300 h-16">
                            Delete
                        </button>
                    }
                </div>
            </form>
        </AdminLayout>
    );
}

const mapDispatchToProps = (dispatch) => ({
    doFirebaseCreateCollection: (data) => dispatch(firebaseCreateCollection(data)),
    doFirebaseUpdateCollection: (data) => dispatch(firebaseUpdateCollection(data)),
    doFirebaseDeleteCollection: (data) => dispatch(firebaseDeleteCollection(data)),
    doFirebaseGetCollection: (data) => dispatch(firebaseGetCollection(data)),
});

const mapStateToProps = (state) => ({
    state: state.firestore,
    user: state.userSlice.user,
    token: state.userSlice.customToken,
    post: state.firestore.posts.edges,
});

export default connect(mapStateToProps, mapDispatchToProps)(PostAdmin);
