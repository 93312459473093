import axios from 'axios';

const fetchNetlifyFunction = (functionName, body = {}) => {
    return axios.post(`/.netlify/functions/${functionName}`, body);
};

export const fetchUserIdApi = () => {
    return fetchNetlifyFunction("getFirebase");
};

export const fetchFirebaseLogin = (user) => {
    return fetchNetlifyFunction("getFirebaseLogin", { user });
};

export const fetchFirebaseLogout = () => {
  return fetchNetlifyFunction("getFirebaseLogout");
};

export const fetchFirebaseGetCustomToken = (data) => {
    return fetchNetlifyFunction("getFirebaseGetCustomToken", { data });
};

export const fetchFirebaseGetPosts = (data) => {
    return fetchNetlifyFunction("getFirebaseGetPosts", { data });
}

export const fetchFirebaseCreateCollection = (data) => {
    return fetchNetlifyFunction("getFirebaseCreateCollection", { data });
}

export const fetchFirebaseUpdateCollection = (data) => {
    return fetchNetlifyFunction("getFirebaseUpdateCollection", { data });
}

export const fetchFirebaseGetCollection = (data) => {
    return fetchNetlifyFunction("getFirebaseGetCollection", { data });
}
