export const FIREBASE_GET_CUSTOM_TOKEN = "FIREBASE_GET_CUSTOM_TOKEN";
export const FIREBASE_GET_POSTS = "FIREBASE_GET_POSTS";
export const FIREBASE_CREATE_COLLECTION = "FIREBASE_CREATE_COLLECTION";
export const FIREBASE_UPDATE_COLLECTION = "FIREBASE_UPDATE_COLLECTION";
export const FIREBASE_DELETE_COLLECTION = "FIREBASE_DELETE_COLLECTION";
export const FIREBASE_GET_COLLECTION = "FIREBASE_GET_COLLECTION";

export const firebaseGetPosts = (data) => ({
    type: FIREBASE_GET_POSTS,
    payload: data,
});

export const firebaseGetCustomToken = (data) => ({
    type: FIREBASE_GET_CUSTOM_TOKEN,
    payload: data,
});

export const firebaseCreateCollection = (data) => ({
    type: FIREBASE_CREATE_COLLECTION,
    payload: data,
});

export const firebaseUpdateCollection = (data) => ({
    type: FIREBASE_UPDATE_COLLECTION,
    payload: data,
});

export const firebaseDeleteCollection = (data) => ({
    type: FIREBASE_DELETE_COLLECTION,
    payload: data,
});

export const firebaseGetCollection = (data) => ({
    type: FIREBASE_GET_COLLECTION,
    payload: data,
});






export const FIREBASE_GET_IDIOM = "FIREBASE_GET_IDIOM";
export const FIREBASE_GET_IDIOMS = "FIREBASE_GET_IDIOMS";
export const FIREBASE_APPEND_IDIOMS = "FIREBASE_APPEND_IDIOMS";
export const FIREBASE_CREATE_IDIOM = "FIREBASE_CREATE_IDIOM";
export const FIREBASE_UPDATE_IDIOM = "FIREBASE_UPDATE_IDION";
export const FIREBASE_DELETE_IDIOM = "FIREBASE_DELETE_IDIOM";
export const FIREBASE_CREATE_INSPIRATION = "FIREBASE_CREATE_INSPIRATION";
export const FIREBASE_UPDATE_INSPIRATION = "FIREBASE_UPDATE_INSPIRATION";
export const FIREBASE_GET_INSPIRATION = "FIREBASE_GET_INSPIRATION";
export const FIREBASE_GET_PAGINATED_COLLECTION =  "FIREBASE_GET_PAGINATED_COLLECTION";
export const FIREBASE_DELETE_INSPIRATION = "FIREBASE_DELETE_INSPIRATION";

export const firebaseDeleteInspiration = (data) => ({
    type: FIREBASE_DELETE_INSPIRATION,
    payload: data,
});

export const firebaseCreateInspiration = (data) => ({
    type: FIREBASE_CREATE_INSPIRATION,
    payload: data,
});

export const firebaseGetInspiration = (data) => ({
    type: FIREBASE_GET_INSPIRATION,
    payload: data,
});

export const firebaseUpdateInspiration = (data) => ({
    type: FIREBASE_UPDATE_INSPIRATION,
    payload: data,
});

export const firebaseGetPaginatedCollection = (data) => ({
    type: FIREBASE_GET_PAGINATED_COLLECTION,
    payload: data,
});



export const firebaseCreateIdiom = (data) => ({
    type: FIREBASE_CREATE_IDIOM,
    payload: data,
});

export const firebaseUpdateIdiom = (data) => ({
    type: FIREBASE_UPDATE_IDIOM,
    payload: data,
});

export const firebaseDeleteIdiom = (data) => ({
    type: FIREBASE_DELETE_IDIOM,
    payload: data,
})

export const firebaseGetIdioms = (data) => ({
    type: FIREBASE_GET_IDIOMS,
    payload: data,
});

export const firebaseAppendIdioms = (data) => ({
    type: FIREBASE_APPEND_IDIOMS,
    payload: data,
});

export const firebaseGetIdiom = (data) => ({
    type: FIREBASE_GET_IDIOM,
    payload: data,
});
