import React, { useCallback, useEffect } from "react";
import { Link } from "react-router-dom";
import Layout from "../components/Layout";
import { connect } from "react-redux";
import { Helmet } from 'react-helmet';

import {
    firebaseGetPosts,
} from "./../store/Actions";

function Home({
    posts,
    isLoading,
    doFirebaseGetPosts,
}) {
    const memoizedDoFirebaseGetPosts = useCallback(() => {
        doFirebaseGetPosts({
            limit: 1,
            cursorEntity: "none",
            cursorType: "startAfter",
        });
    }, [doFirebaseGetPosts]);

    useEffect(() => {
        memoizedDoFirebaseGetPosts()
    }, [memoizedDoFirebaseGetPosts]);
    return (
        <>
            <Helmet>
                <title>Web Dev Warrior - Home</title>
                <meta name="description" content="Crafting Digital Excellence, One Code at a Time."/>
                {/*Twitter Card data*/}
                <meta name="twitter:card" content="summary_large_image"/>
                <meta name="twitter:site" content="@webdevwarrior"/>
                <meta name="twitter:title" content="Web Dev Warrior"/>
                <meta name="twitter:description" content="Page description less than 200 characters"/>
                <meta name="twitter:creator" content="@webdevwarrior"/>
                {/*Twitter summary card with large image must be at least 280x150px*/}
                <meta name="twitter:image:src" content="http://www.webdevwarrior.com/images/blog_7.jpg"/>

                {/*Open Graph data*/}
                <meta property="og:title" content="Web Dev Warrior" />
                <meta property="og:type" content="article" />
                <meta property="og:url" content="http://www.webdevwarrior.com/" />
                <meta property="og:image" content="http://webdevwarrior.com/image.jpg" />
                <meta property="og:description" content="Description Here" />
                <meta property="og:site_name" content="Web Dev Warrior" />
                <meta property="article:published_time" content="2013-09-17T05:59:00+01:00" />
                <meta property="article:modified_time" content="2013-09-16T19:08:47+01:00" />
                <meta property="article:section" content="Article Section" />
                <meta property="article:tag" content="Article Tag" />
                <meta property="fb:admins" content="Facebook numberic ID" />
            </Helmet>
            <Layout>
                <div className="max-w-6xl m-auto">
                    <img src="./images/blog_hero_image.jpg" className="m-auto w-full" alt="multiple colors around laptop"/>
                </div>

                <div className="max-w-6xl m-auto grid lg:grid-cols-3 md:grid-cols-3 gap-4 px-3 pt-7">
                    <div className="col-span-1 bg-clearly-white rounded-xl p-4 flex flex-col">
                        <span className="text-bark-blue underline text-2xl font-bold text-center">Tutorials</span>
                        <div className="">
                            <span className="font-ide text-md text-left">
                                <img src="./images/blog_3.jpg" alt="pseudo code"/>
                                Tutorials I write about both front end and backend technology
                                stacks.  Hopefully these are useful and answer questions that people
                                may have.
                            </span>
                        </div>
                        <Link to="/posts" className="bg-orange-button hover:bg-orange-500 p-2 text-white rounded-lg text-center">Tutorials</Link>
                    </div>
                    <div className="col-span-1 bg-clearly-white rounded-xl text-center p-4 flex flex-col">
                        <span className="text-bark-blue underline text-2xl font-bold">Life</span>
                        <div className="">
                            <span className="font-ide text-md text-left">
                                <img src="./images/blog_7.jpg" alt="A man relaxing at a doc"/>
                                It is all on the table, talking about life and getting over being
                                incarcated due to an untreated mental illness.  Life, Job hunting
                                and get life back to normal.
                            </span>
                        </div>
                        <Link to="/posts" className="bg-orange-button hover:bg-orange-500 p-2 text-white rounded-lg text-center w-full">Life</Link>
                    </div>
                    { !isLoading &&
                        <div className="col-span-1 bg-clearly-white rounded-xl text-center p-4 flex flex-col">
                            <span className="text-bark-blue underline text-2xl font-bold">Recent</span>
                            <div className="">
                                <img src={`./images/${ posts[0]?.mainImage }`} alt={posts[0]?.altText}/>
                                <span className="font-ide text-md text-left">
                                    <b>{posts[0]?.title}</b><br/>
                                    { posts[0]?.metaDescription }
                                </span>
                            </div>
                            <Link to={`/posts/${posts[0]?.uuid}`} className="bg-orange-button hover:bg-orange-500 p-2 text-white rounded-lg text-center w-full">Life</Link>
                        </div>
                    }
                </div>
            </Layout>
        </>
    );
}

const mapDispatchToProps = (dispatch) => ({
    doFirebaseGetPosts: (cursor) => dispatch(firebaseGetPosts(cursor)),
});

const mapStateToProps = (state) => ({
    isLoading: state.firestore.isLoading,
    posts: state.firestore.posts.edges,
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);
