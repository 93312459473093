import React, { useEffect, useCallback } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import AdminLayout from "../../components/admin/Layout";
import {
    firebaseGetPosts,
} from "../../store/Actions";

const PostsAdmin = ({
    isLoading,
    posts,
    hasMore,
    hasPrevious,
    doFirebaseGetPosts,
}) => {
    const memoizedDoFirebaseGetPosts = useCallback(() => {
        doFirebaseGetPosts({
            limit: 10,
            cursorEntity: "none",
            cursorType: "startAfter",
        });
    }, [doFirebaseGetPosts]);

    useEffect(() => {
        memoizedDoFirebaseGetPosts()
    }, [memoizedDoFirebaseGetPosts]);

    const handleNext = () => {
        doFirebaseGetPosts({
            limit: 10,
            cursorEntity: posts[posts.length - 1]["uuid"],
            cursorType: "startAfter",
        });
    }

    const handlePrevious = () => {
        doFirebaseGetPosts({
            limit: 10,
            cursorEntity: posts[0]["uuid"],
            cursorType: "endBefore",
        });
    }
    return (
        <AdminLayout>
            <div className="text-3xl p-4 pl-10 bg-stone-500/80">
                Home > Posts >
                <Link to="/admin/post/new" className="bg-green-200 hover:bg-green-300 rounded-lg p-2 m-2">Add New Post</Link>
            </div>
            <div className="max-w-5xl p-10">
                <table className="table-auto bg-slate-400 w-full text-center">
                    <thead className="bg-slate-500 text-lg">
                        <tr>
                            <th>uuid</th>
                            <th>title</th>
                            <th>created</th>
                            <th>actions</th>
                        </tr>
                    </thead>
                    <tbody className="text-xl">
                        {
                            Object.entries(posts || {}).map((post, index) => {
                                return (
                                    <tr key={post[1].uuid} className="hover:bg-slate-300">
                                        <td>{post[1].uuid}</td>
                                        <td>{post[1].title}</td>
                                        <td>{post[1].type}</td>
                                        <td className="float-right p-2">
                                            <Link to={`/admin/post/${post[1].uuid}`}className="m-2 p-1 bg-green-200 hover:bg-green-300 rounded-md">edit</Link>
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
                <div className="grid grid-cols-2 gap-10 text-center pt-5">
                    { hasPrevious !== false &&
                        <button className="bg-white hover:bg-indigo-100 border-2 border-indigo-200 m-2 p-2 col-start-1" onClick={handlePrevious}>Previous</button>
                    }
                    { hasMore !== false &&
                        <button className="bg-white hover:bg-indigo-100 border-2 border-indigo-200 m-2 p-2 col-start-2" onClick={handleNext}>Next</button>
                    }
                </div>
            </div>
        </AdminLayout>
    );
}

const mapDispatchToProps = (dispatch) => ({
    doFirebaseGetPosts: (cursor) => dispatch(firebaseGetPosts(cursor)),
});

const mapStateToProps = (state) => ({
    isLoading: state.firestore.isLoading,
    posts: state.firestore.posts.edges,
    hasMore: state.firestore.posts.hasMore,
    hasPreviousPage: state.firestore.posts.hasPreviousPage,
});

export default connect(mapStateToProps, mapDispatchToProps)(PostsAdmin);
