import Header from "../components/Header";

function Layout({ children }) {
    return (
        <div className="bg-main pt-8 min-h-screen">
            <Header/>
            <main>
                { children }
            </main>
        </div>
    )
}

export default Layout;
