import { Link } from "react-router-dom";
import Layout from "../components/Layout";

function Categories() {
    return (
        <Layout>
            <div className="max-w-6xl m-auto grid grid-cols-1 md:grid-cols-2 gap-4 px-6 py-7">
                <div className="col-span-1 bg-clearly-white rounded-xl py-4 px-4 flex flex-col">
                    <span className="text-bark-blue underline text-2xl font-bold text-center">
                        Tutorials
                    </span>
                    <div className="grid grid-cols-3 py-4 gap-4">
                        <div className="col-span-1">
                            <img src="./images/code_2.jpg" alt="pseudo code"/>
                        </div>
                        <div className="col-span-2">
                            <span className="font-ide text-md text-left">
                                Come checkout this tutorial where I show you
                                how to create a CMS blog using free software bundles.
                                Come get started today!
                            </span>
                        </div>
                    </div>
                    <Link to="/posts" className="bg-orange-button hover:bg-orange-500 p-2 text-white text-center rounded-lg">
                        Tutorials
                    </Link>
                </div>
                <div className="col-span-1 bg-clearly-white rounded-xl py-4 px-4 flex flex-col">
                    <span className="text-bark-blue underline text-2xl font-bold text-center">
                        Life
                    </span>
                    <div className="grid grid-cols-3 py-4 gap-4">
                        <div className="col-span-1">
                            <img src="./images/code_2.jpg" alt="pseudo code"/>
                        </div>
                        <div className="col-span-2">
                            <span className="font-ide text-md text-left">
                                Come checkout this tutorial where I show you
                                how to create a CMS blog using free software bundles.
                                Come get started today!
                            </span>
                        </div>
                    </div>
                    <button className="bg-blue-button hover:bg-blue-500 p-2 text-white rounded-lg">
                        Life
                    </button>
                </div>
            </div>
        </Layout>
    );
}

export default Categories;
