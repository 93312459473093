import React from "react";

function CustomInput({ 
    onChange,
    value,
    name,
    type = "text",
    placeholder,
    disabled,
    maxlength = "false",
}) {
    return (
        <>
            { type === "text" &&
                <input className="text-2xl rounded-lg shadow-lg p-3 w-full"
                    type={type}
                    name={name}
                    placeholder={placeholder}
                    value={value}
                    onChange={onChange}
                    disabled={disabled}
                    maxLength={maxlength}/>
            }
            { type === "textarea" &&
                <textarea className="text-2xl rounded-lg shadow-lg p-3 w-full"
                    type={type}
                    name={name}
                    placeholder={placeholder}
                    value={value}
                    onChange={onChange}
                    disabled={disabled}
                    rows="5"/>
            }
        </>
    )
}

export default CustomInput;
