import React, { useState } from 'react';
import { Link } from "react-router-dom";

function Header() {
    const [isNavOpen, setIsNavOpen] = useState(false); // initiate isNavOpen state with false
    return (
        <header className="bg-menu-bar border-b-menu-bar-border border-b-2 border-t-menu-bar-border border-t-2">
            <nav className="max-w-6xl m-auto text-lg md:flex flex-row justify-between px-10 pt-3 pb-1">
                <div className="justify-center text-center -mt-1 md:w-1/3 w-3/4 flex flex-row pt-2">
                    <Link to="/" className="text-3xl font-bold text-white">Web Dev Warrior</Link>
                </div>
                <div className="float-right text-right md:hidden w-1/4 -mt-11">
                    <button type="button"
                        className=" inline-flex items-center justify-center rounded-md p-2.5 text-gray-700  bg-white"
                        onClick={() => setIsNavOpen((prev) => !prev)}
                        >
                        <span className="sr-only">Open main menu</span>
                        <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                        </svg>
                    </button>
                </div>
                <div className="hidden md:flex float-right text-right md:w-1/3">
                    <Link to="/" className="bg-blue-button hover:bg-blue-500
                        rounded-lg p-1 m-1 font-bold text-white text-center">Home</Link>
                    <Link to="/posts" className="bg-green-button hover:bg-green-500
                        rounded-lg p-1 m-1 font-bold text-white text-center">Blog</Link>
                    <Link to="/tags" className="bg-orange-button hover:bg-orange-500
                        rounded-lg p-1 m-1 font-bold text-white text-center">Tags</Link>
                    <Link to="/about" className="bg-red-button hover:bg-red-500
                        rounded-lg p-1 m-1 font-bold text-white text-center">About</Link>
                    <Link to="/login" className="bg-red-button hover:bg-red-500
                        rounded-lg p-1 m-1 font-bold text-white text-center underline">Login</Link>
                </div>
            </nav>
            <div className={`h-32 max-w-6xl md:hidden ${isNavOpen ? "" : "hidden"}`} role="dialog" aria-modal="true">
                <div className="fixed inset-0 z-50"></div>
                <div className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
                    <div className="flex items-center justify-between">
                        <Link to="/" className="-m-1.5 p-1.5">
                            <img className="w-10 float-left"
                                src="./logo2.png"
                                alt=""/>
                            <span className="float-right pt-1">Web Dev Warrior</span>
                        </Link>
                        <button type="button"
                            className="-m-2.5 rounded-md p-2.5 text-gray-700"
                            onClick={() => setIsNavOpen(false)}>
                            <span className="sr-only">Close menu</span>
                            <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>
                    </div>
                    <div className="mt-6 flow-root">
                        <div className="-my-6 divide-y divide-gray-500/10">
                            <div className="space-y-2 py-6">
                                <Link to="/" className="bg-blue-button hover:bg-blue-500
                                    rounded-lg p-1 m-1 font-bold text-white text-center">Home</Link>
                                <Link to="/posts" className="bg-green-button hover:bg-green-500
                                    rounded-lg p-1 m-1 font-bold text-white text-center">Blog</Link>
                                <Link to="/tags" className="bg-orange-button hover:bg-orange-500
                                    rounded-lg p-1 m-1 font-bold text-white text-center">Tags</Link>
                                <Link to="/about" className="bg-red-button hover:bg-red-500
                                    rounded-lg p-1 m-1 font-bold text-white text-center">About</Link>
                                <Link to="/login" className="bg-red-button hover:bg-red-500
                                    rounded-lg p-1 m-1 font-bold text-white text-center underline">Login</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    )
}

export default Header;
