import React from "react";
import { connect } from "react-redux";
import AdminLayout from "../../components/admin/Layout";

const Admin = () => {
    return (
        <AdminLayout>
            <div className="text-3xl p-2 pl-10 bg-stone-500/80">Home</div>
            <div className="flex flex-col max-w-xl p-5">

            </div>
        </AdminLayout>
    );
}

const mapStateToProps = (state) => ({
    state: state.firestore,
    user: state.userSlice.user,
});

export default connect(mapStateToProps, null)(Admin);
