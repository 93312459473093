import { put, call, takeLatest, takeLeading, all, fork } from 'redux-saga/effects';
import {
    FIREBASE_GET_CUSTOM_TOKEN,
    FIREBASE_GET_POSTS,
    FIREBASE_CREATE_COLLECTION,
    FIREBASE_UPDATE_COLLECTION,
    FIREBASE_GET_COLLECTION,
    firestoreSetCollection,
    firestoreSetIsLoading,
} from '../../store/Actions';

import {
    fetchFirebaseGetCustomToken,
    fetchFirebaseGetPosts,
    fetchFirebaseCreateCollection,
    fetchFirebaseUpdateCollection,
    fetchFirebaseGetCollection,
} from '../../api/Api';

import {
    setUserToken,
} from "../../reducers/UserSlice";


function* getFirebaseGetCustomToken(data) {
    const token = yield call(fetchFirebaseGetCustomToken, data.payload);
    yield put(setUserToken(token.data.customToken));
}

function* getFirebaseGetPosts(data) {
    yield put(firestoreSetIsLoading(true));
    yield put(firestoreSetCollection({
        "collection": "posts",
        "data": { "edges" : null },
    }));
    const posts = yield call(fetchFirebaseGetPosts, data.payload);
    yield put(firestoreSetCollection({
        "collection": "posts",
        "data": posts.data,
    }));
    yield put(firestoreSetIsLoading(false));
}

function* getFirebaseGetCollection(data) {
    yield put(firestoreSetIsLoading(true));
    const collection = yield call(fetchFirebaseGetCollection, data.payload);
    yield put(firestoreSetCollection({
        "collection": data.payload.table,
        "data" : { "edges": collection.data.data },
    }));
    yield put(firestoreSetIsLoading(false));
}

function* getFirebaseCreateCollection(data) {

    yield call(fetchFirebaseCreateCollection, data.payload);
}

function* getFirebaseUpdateCollection(data) {
    console.log(data);
    yield call(fetchFirebaseUpdateCollection, data.payload);
}

function* watchDataSaga() {
    yield takeLatest(FIREBASE_GET_CUSTOM_TOKEN, getFirebaseGetCustomToken);

    yield takeLeading(FIREBASE_GET_POSTS, getFirebaseGetPosts);

    yield takeLeading(FIREBASE_GET_COLLECTION, getFirebaseGetCollection);
    yield takeLeading(FIREBASE_CREATE_COLLECTION, getFirebaseCreateCollection);
    yield takeLeading(FIREBASE_UPDATE_COLLECTION, getFirebaseUpdateCollection);
}

export default function* dataSaga() {
    yield all([
        fork(watchDataSaga),
    ]);
}

