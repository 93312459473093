import Layout from "../components/Layout";
import { Helmet } from 'react-helmet';

function About(){
    return (
        <>
            <Helmet>
                <title>Web Dev Warrior - About Me</title>
                <meta name="description" content="Explore Jaron's journey as a software engineer with 8 years of experience, navigating the challenges of mental illness and reintegration after incarceration. Discover insights on technology, mental health, and rebuilding life, shared with honesty and hope."/>
                {/*Twitter Card data*/}
                <meta name="twitter:card" content="summary_large_image"/>
                <meta name="twitter:site" content="@publisher_handle"/>
                <meta name="twitter:title" content="Web Dev Warrior"/>
                <meta name="twitter:description" content="Explore Jaron's journey as a software engineer with 8 years of experience, navigating the challenges of mental illness and reintegration after incarceration. Discover insights on technology, mental health, and rebuilding life."/>
                <meta name="twitter:creator" content="@author_handle"/>
                {/*Twitter summary card with large image must be at least 280x150px*/}
                <meta name="twitter:image:src" content="http://www.webdevwarrior.com/image.jpg"/>

                {/*Open Graph data*/}
                <meta property="og:title" content="Title Here" />
                <meta property="og:type" content="article" />
                <meta property="og:url" content="http://www.webdevwarrior.com/" />
                <meta property="og:image" content="http://webdevwarrior.com/image.jpg" />
                <meta property="og:description" content="Explore Jaron's journey as a software engineer with 8 years of experience, navigating the challenges of mental illness and reintegration after incarceration. Discover insights on technology, mental health, and rebuilding life, shared with honesty and hope." />
                <meta property="og:site_name" content="Web Dev Warrior" />
                <meta property="article:published_time" content="2013-09-17T05:59:00+01:00" />
                <meta property="article:modified_time" content="2013-09-16T19:08:47+01:00" />
                <meta property="article:section" content="Article Section" />
                <meta property="article:tag" content="Article Tag" />
                <meta property="fb:admins" content="Facebook numberic ID" />
            </Helmet>
            <Layout>
                <article className="max-w-6xl m-auto px-6 py-7">
                    <div className="bg-clearly-white rounded-xl py-6 px-6 flex flex-col">
                        <h1 className="text-bark-blue underline text-5xl font-bold text-center">About Me</h1>
                        <div className="p-4">
                            <span className="font-ide text-lg text-left">
                                <img className="float-right w-64"src="/images/abstractCode_1.jpg" alt="pseudo code"/>
                                <br/>
                                Hello and welcome to my blog! I'm a passionate software engineer with over 8 years of experience in the field. My journey has been unconventional, marked by both professional achievements and personal challenges. Through this blog, I aim to share my story – a blend of technology, personal growth, and resilience.
                                <br/>
                                <br/>
                                <b>My Professional Background:</b>
                                <br/>
                                <br/>
                                I embarked on my career in software engineering in PHP about a decade ago. Over the years, I've honed my skills in full stack design, always driven by a love for innovation and problem-solving. My career has been a journey of continuous learning and adaptation, reflecting the ever-evolving landscape of technology.
                                <br/>
                                <br/>
                                <b>Facing Personal Challenges:</b>
                                <br/>
                                <br/>
                                A few years ago, my life took an unexpected turn. I was diagnosed with Schizo-Affective Disorder, which significantly impacted my life and career. During this period, I made decisions that led me to serve two years in jail. This experience was a turning point, opening my eyes to the complexities of mental health and the justice system.
                                <br/>
                                <br/>
                                <b>The Essence of My Blog:</b>
                                <br/>
                                <br/>
                                This blog is more than just a platform; it's a part of my journey towards recovery and rediscovery. Here, I write about:
                                <br/>
                                <br/>
                                <ul>
                                    <li><b>Mental Illness:</b> Sharing insights and experiences to raise awareness and support others facing similar challenges.</li>
                                    <li><b>Technology:</b> Keeping up with the latest trends, sharing my knowledge, and exploring how technology can be a force for good.</li>
                                    <li><b>Life After Incarceration:</b> Discussing the realities of re-entering society and the workforce, and the paths to rebuilding one's life.</li>
                                </ul>
                                <br/>
                                <b>Looking Ahead:</b>
                                <br/>
                                <br/>
                                Today, as I navigate the path to re-establishing my career in software development, I am more committed than ever to using my experiences – both professional and personal – to make a positive impact. I believe in the power of technology to transform lives and the importance of empathy and understanding in our society.
                                <br/>
                                <br/>
                                <b>Connect with Me:</b>
                                <br/>
                                <br/>
                                I invite you to join me on this journey. Whether you're here for insights into the tech world, to understand more about mental health, or to hear a story of resilience and hope, I'm glad you're here. Feel free to reach out, connect, and share your stories too.
                                <br/>


                            </span>
                        </div>
                    </div>
                </article>
            </Layout>
        </>
    );
}

export default About;
