import { combineReducers } from "redux";

import FirestoreReducer from "../reducers/FirestoreReducer";
import UserSlice from "../reducers/UserSlice";

const rootReducer = combineReducers({
    firestore: FirestoreReducer,
    userSlice: UserSlice,
});

export default rootReducer;
