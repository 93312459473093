import React, { useCallback, useEffect } from "react";
import Layout from "../components/Layout";
import { Helmet } from 'react-helmet';
import { connect } from "react-redux";
import { useParams } from 'react-router-dom';
import {
    firebaseGetCollection,
} from "./../store/Actions";
function Blog({
    doFirebaseGetCollection,
    post,
    isLoading,
}) {
    const { slug } = useParams();
    const memoizedDoFirebaseGetCollection = useCallback(() => {
        doFirebaseGetCollection({
            "table":"posts",
            "slug": slug
        });
    }, [doFirebaseGetCollection, slug]);
    useEffect(() => {
        memoizedDoFirebaseGetCollection()
    }, [memoizedDoFirebaseGetCollection]);

    return (
        <>
            <Helmet>
                <title>{ post.metaTitle }</title>
                <meta name="description" content={ post.metaDescription }/>

                {/*Twitter Card data*/}
                <meta name="twitter:card" content={ post.altText }/>
                <meta name="twitter:site" content="@webdevwarrior"/>
                <meta name="twitter:title" content={ post.metaTitle }/>
                <meta name="twitter:description" content={ post.metaDescription }/>
                <meta name="twitter:creator" content="@webdevwarrior"/>
                {/*Twitter summary card with large image must be at least 280x150px*/}
                <meta name="twitter:image:src" content={`https://www.webdevwarrior.com/images/${ post.mainImage }`}/>

                {/*Open Graph data*/}
                <meta property="og:title" content={ post.metaTitle } />
                <meta property="og:type" content="article" />
                <meta property="og:url" content="http://www.WebDevWarrior.com/" />
                <meta property="og:image" content={`https://www.webdevwarrior.com/images/${ post.mainImage }`} />
                <meta property="og:description" content={ post.metaDescription } />
                <meta property="og:site_name" content="Web Dev Warrior" />
                <meta property="article:published_time" content="2013-09-17T05:59:00+01:00" />
                <meta property="article:modified_time" content="2013-09-16T19:08:47+01:00" />
                <meta property="article:section" content="Article Section" />
                <meta property="article:tag" content={ post.type } />
                <meta property="fb:admins" content="Facebook numberic ID" />

            </Helmet>
            <Layout>
                <article className="max-w-6xl m-auto px-3 py-7">
                    { !isLoading &&
                        <div className="bg-clearly-white rounded-xl py-6 px-6 flex flex-col">
                            <h1 className="text-bark-blue text-5xl font-bold text-center pb-3">
                                { post.title }
                            </h1>
                            <div className="max-w-md m-auto">
                                <img src={`/images/${ post.mainImage }`} alt={ post.altText }/>
                            </div>
                            <div className="p-4">
                                <span dangerouslySetInnerHTML={{ __html: post.body }} className="font-ide text-lg text-left"></span>
                            </div>
                        </div>
                    }
                </article>
            </Layout>
        </>
    );
}

const mapDispatchToProps = (dispatch) => ({
    doFirebaseGetCollection: (data) => dispatch(firebaseGetCollection(data)),
});

const mapStateToProps = (state) => ({
    isLoading: state.firestore.isLoading,
    post: state.firestore.posts.edges,
});

export default connect(mapStateToProps, mapDispatchToProps)(Blog);
