import { all, fork } from "redux-saga/effects";
import authSaga from "./saga/authSagas";
import dataSaga from './saga/dataSagas';

function* rootSaga() {
  yield all([
    yield fork(authSaga),
    yield fork(dataSaga),
    // Add other sagas here if needed
  ]);
}

export default rootSaga;
