import React, { useEffect } from "react";
import {
    Routes,
    Route,
    useLocation,
} from "react-router-dom";
import AuthGuard from "./guards/AuthGuard";
import GuestGuard from "./guards/GuestGuard";
import Home from "./pages/Home";
import Posts from "./pages/Posts";
import Post from "./pages/Post";
import About from "./pages/About";
import Tags from "./pages/Tags";

import Login from "./pages/auth/Login";

import Admin from "./pages/admin/Admin";
import PostsAdmin from "./pages/admin/Posts";
import PostAdmin from "./pages/admin/Post";

import ReactGA from "react-ga4";
const TRACKING_ID = "G-NP4Z264XS2";

function App() {
    const location = useLocation();
    ReactGA.initialize(TRACKING_ID);
    useEffect(() => {
        ReactGA.set({ page: location.pathname });
    }, [location]);
    return (
        <Routes location={location}>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/posts" element={<Posts />} />
            <Route exact path="/posts/:slug" element={<Posts />} />
            <Route exact path="/post/:slug" element={<Post />} />
            <Route exact path="/about" element={<About />} />
            <Route exact path="/tags" element={<Tags />} />

            <Route exact path="/login" element={<GuestGuard><Login/></GuestGuard>} />

            <Route exact path="/admin" element={<AuthGuard><Admin/></AuthGuard>} />
            <Route exact path="/admin/posts" element={<AuthGuard><PostsAdmin/></AuthGuard>} />
            <Route exact path="/admin/post/:slug" element={<AuthGuard><PostAdmin/></AuthGuard>} />
        </Routes>
    );
}

export default App;
