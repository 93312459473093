import React, { useCallback, useEffect } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import Layout from "../components/Layout";
import {
    firebaseGetPosts,
} from "./../store/Actions";

function Posts({
    posts,
    isLoading,
    hasMore,
    hasPreviousPage,
    doFirebaseGetPosts,
}) {
    const { slug } = useParams();
    const memoizedDoFirebaseGetPosts = useCallback(() => {
        let slugToUse;
        if (slug === undefined) {
            slugToUse = "none";
        } else {
            slugToUse = slug;
        }
        doFirebaseGetPosts({
            limit: 10,
            cursorEntity: slugToUse,
            cursorType: "startAfter",
        });
    }, [doFirebaseGetPosts,slug]);

    useEffect(() => {
        memoizedDoFirebaseGetPosts()
    }, [memoizedDoFirebaseGetPosts]);

    const formatTime = (date) => {
        const timestamp = new Date(date?.seconds * 1000)
        return  timestamp.toDateString();
    }
    return (
        <Layout>
            <Helmet>
                <title>Web Dev Warrior - Blog Post</title>
                <meta name="description" content="The blog post page to my website."/>
            </Helmet>
            {
                !isLoading &&
                <>
                    <div className="max-w-6xl m-auto grid grid-cols-1 md:grid-cols-2 gap-4 px-6 py-7">
                        {
                            Object.entries(posts || {}).map((post, index) => {
                                return (
                                    <article key={ post[1]?.uuid } className="col-span-1 bg-clearly-white rounded-xl py-4 px-4 flex flex-col">
                                        <Link to={`/post/${post[1]?.uuid}`} className="text-bark-blue text-2xl font-bold text-left underline">
                                            { post[1]?.title }
                                        </Link>
                                        <div>
                                            Date Published: { formatTime(post[1]?.created) }
                                        </div>
                                        <div className="grid grid-cols-3 py-4 gap-4">
                                            <Link to={`/post/${post[1]?.uuid}`} className="col-span-1">
                                                <img src={`/images/${ post[1]?.mainImage }`} alt={ post[1]?.altText }/>
                                            </Link>
                                            <div className="col-span-2">
                                                <span className="font-ide text-md text-left">
                                                    { post[1]?.metaDescription }
                                                </span>
                                            </div>
                                        </div>
                                    </article>
                                )
                            })
                        }
                    </div>
                    <div className="max-w-6xl m-auto grid grid-cols-2 px-6 pb-10 text-4xl gap-4">
                        { hasPreviousPage &&
                            <div className="col-start-1 flex flex-row">
                                <Link to={`/posts/${hasPreviousPage}`} className="bg-red-button hover:bg-red-500 p-2 text-white text-center rounded-lg w-full">Previous</Link>
                            </div>
                        }
                        { hasMore &&
                            <div className="col-start-2 flex flex-row">
                                <Link to={`/posts/${hasMore}`} className="bg-red-button hover:bg-red-500 p-2 text-white text-center rounded-lg w-full">Next</Link>
                            </div>
                        }
                    </div>
                </>
            }
        </Layout>
    );
}

const mapDispatchToProps = (dispatch) => ({
    doFirebaseGetPosts: (cursor) => dispatch(firebaseGetPosts(cursor)),
});

const mapStateToProps = (state) => ({
    isLoading: state.firestore.isLoading,
    posts: state.firestore.posts.edges,
    hasMore: state.firestore.posts.hasMore,
    hasPreviousPage: state.firestore.posts.hasPreviousPage,
});

export default connect(mapStateToProps, mapDispatchToProps)(Posts);
