import { put, call, takeLatest, fork, all } from 'redux-saga/effects';
import {
    AUTH_LOGIN,
    AUTH_LOGOUT,
} from '../../store/Actions';

import {
    fetchFirebaseLogin,
    fetchFirebaseLogout,
} from '../../api/Api';

import {
    setUser,
    clearUserData,
} from "../../reducers/UserSlice";

function* getAuthLogin(data) {
    const login = yield call(fetchFirebaseLogin, data.payload);
    yield put(setUser(login.data));
}

function* getAuthLogout(data) {
    yield call(fetchFirebaseLogout);
    yield put(clearUserData());
}

function* watchAuthSaga() {
    yield takeLatest(AUTH_LOGIN, getAuthLogin);
    yield takeLatest(AUTH_LOGOUT, getAuthLogout);
}

export default function* authSagas() {
    yield all([
        fork(watchAuthSaga),
    ]);
}
