import {
   FIRESTORE_SET_COLLECTION,
   FIRESTORE_SET_IS_LOADING,
} from '../store/Actions';

const initialState = {
    posts: {
            edges: [],
            pageInfo: {
                hasMore: false,
                hasPreviousPage: false,
            }
        },
    isLoading: true,
};

const FirestoreReducer = (state = initialState, action) => {
    switch(action.type) {
        case FIRESTORE_SET_COLLECTION:
            let returnValue = { ...state };
            returnValue[action.payload.collection] = action.payload.data;
            return returnValue;
        case FIRESTORE_SET_IS_LOADING:
            return {
                ...state,
                isLoading: action.payload,
            }
        default:
            return {
                ...state,
            }
    }
}

export default FirestoreReducer;
