export const FIRESTORE_SET_COLLECTION = "FIRESTORE_SET_COLLECTION";

export const firestoreSetCollection = (data) => ({
    type: FIRESTORE_SET_COLLECTION,
    payload: data,
});

export const firestoreSetIsLoading = (data) => ({
    type: FIRESTORE_SET_IS_LOADING,
    payload: data,
});


export const FIRESTORE_SET_IDIOMS = "FIRESTORE_SET_IDIOMS";
export const FIRESTORE_SET_IDIOM = "FIRESTORE_SET_IDIOM";
export const FIRESTORE_APPEND_IDIOMS = "FIRESTORE_APPEND_IDIOMS"
export const FIRESTORE_SET_IS_LOADING = "FIRESTORE_SET_IS_LOADING";
export const FIRESTORE_SET_HAS_MORE = "FIRESTORE_SET_HAS_MORE";
export const FIRESTORE_SET_HAS_PREVIOUS_PAGE = "FIRESTORE_SET_HAS_PREVIOUS_PAGE";
export const FIRESTORE_SET_INSPIRATION = "FIRESTORE_SET_INSPIRATION";

export const firestoreSetInspiration = (data) => ({
    type: FIRESTORE_SET_INSPIRATION,
    payload: data,
});



export const firestoreAppendIdioms = (data) => ({
    type: FIRESTORE_APPEND_IDIOMS,
    payload: data,
});

export const firestoreSetIdioms = (data) => ({
    type: FIRESTORE_SET_IDIOMS,
    payload: data,
});

export const firestoreSetIdiom = (data) => ({
    type: FIRESTORE_SET_IDIOM,
    payload: data,
});

export const firestoreSetHasMore = (data) => ({
    type: FIRESTORE_SET_HAS_MORE,
    payload: data,
});

export const firestoreSetHasPreviousPage = (data) => ({
    type: FIRESTORE_SET_HAS_PREVIOUS_PAGE,
    payload: data,
});
